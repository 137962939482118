<template>
  <div class="suggestList">
    <suggest-complaint :id="id" />
  </div>
</template>

<script>
import SuggestComplaint from "./Complaint";


export default {
  data() {
    return {
      id:""
    };
    
    let id = this.$route.params.id;
  },
  created() {
      let id = this.$route.params.id;
      this.id = id;
  },
  components: {
    SuggestComplaint,
  },
};
</script>

<style scoped lang="less">
.suggestList {
  width: 100%;
  box-sizing: border-box;
  /deep/ .van-tab--active {
    background-color: #f7fafc !important;
  }
   /deep/ .van-tabs__line{
      width: 3.73rem !important;
  }

}
</style>