<template>
  <div class="complaint">
    <div class="theme">
      娄庄镇社情民意直通车
    </div>
    <div class="content">
      <p class="title">反馈主要问题</p>
      <van-field
        class="text-area"
        maxlength="200"
        readonly
        v-model="titleData"
        autosize
        type="textarea"
        contenteditable="true"
      />
    </div>
    <div class="content">
      <p class="title">建议/现状</p>
      <van-field
        class="text-area"
        readonly
        v-model="contentData"
        autosize
        type="textarea"
        contenteditable="true"
      />
    </div>
  </div>
</template>
<script>
import { getSuggestDetaill } from "../api";
import Upload from "@/components/Upload";
export default {
  name: "complaint",
  props: ["id"],
  data() {
    return {
      show: false,
      time: 0,
      timer: {},
      titleData: "",
      contentData: "",
      localId: "",
      img: [],
    };
  },
  components: {
    Upload,
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let params = {
        id: this.id,
      };
      getSuggestDetaill(params).then((res) => {
        console.log("res", res);
        this.titleData = res.data.title;
        this.contentData = res.data.content;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.complaint {
  padding: 0.27rem 0.4rem 0;
  padding-bottom: 1rem;
  position: relative;
  min-height: 14.5rem;
  background: url("../../../assets/images/organization/bg.png");
  background-size: 100% 100%;
  .theme {
    width: 5.5rem;
    margin: 0.3rem auto;
    color: #333333;
    font-size: 0.36rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.6rem;
  }

  .title {
    margin-bottom: 0.2rem;
    color: #333333;
  }
  .content {
    border-radius: 0.06rem;
    padding: 0.19rem 0.2rem;
    .text-area {
      border-radius: 0.2rem;
      background-color: #f6faff;
      /deep/ textarea {
        color: #333;
      }
    }
  }
}
</style>